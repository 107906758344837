import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Article from '../components/article'
import Cta from '../components/cta'
import { StaticImage } from 'gatsby-plugin-image'

const LandlordsInsurance = () => {
  return (
    <>
      <Seo
        pageTitle={'Landlords Insurance'}
        description={`Whether you're renting out a second house, or you have a more extensive buy-to-let portfolio, you'll appreciate the importance of ensuring your investment is safely protected. Our Landlords Insurance policy can provide cover for the building of the property, cover for the Landlords contents left in the property as well as the option of adding Home Emergency cover.`}
      />
      <Layout>
        <main>
          <Article>
            <div className='card shadow border-primary mb-3 p-2'>
              <StaticImage
                className='card-img-top'
                src={'../images/landlords.png'}
                alt={'Combined Home Insurance'}
                placeholder='blurred'
                Layout={'fullWidth'}
                formats={['auto', 'webp', 'avif']}
                quality={100}
              ></StaticImage>
            </div>
            <h1 className='display-4'>Landlords Insurance</h1>
            <p>
              Whether you’re renting out a second house, or you have a more
              extensive buy-to-let portfolio, you'll appreciate the importance
              of ensuring your investment is safely protected. Our Landlords
              Insurance policy can provide cover for the building of the
              property, cover for the Landlords contents left in the property as
              well as the option of adding Home Emergency cover.
            </p>
            <p>
              Our flexible cover allows you to add properties to an existing
              policy any time, giving you one simple renewal date and a
              multi-property discount.
            </p>

            <section className='my-3'>
              <h2 className='display-6'>Buildings Insurance</h2>
              <p>We can provide buildings cover up to £750,000 per property.</p>
              <p>On top of that, our Landlords insurance also: </p>
              <ul className='list-group list-group'>
                <li className='list-group-item'>
                  Provides loss of rent cover if necessary (following an insured
                  event)
                </li>
                <li className='list-group-item'>
                  Covers fire, lightning, storm or flood (excluding fences and
                  gates), theft, explosion, escape of water or oil, vandalism
                  and subsidence
                </li>
                <li className='list-group-item'>
                  Covers accidental breakage as standard for underground pipes
                  and cables, plus fixed glass in windows and doors and sanitary
                  fixtures and fittings.
                </li>
                <li className='list-group-item'>
                  Gives you the option to include extended accidental damage for
                  incidents such as DIY accidents and leaving taps running.
                </li>
                <li className='list-group-item'>
                  Gives you the option to include access to a 24-hour Emergency
                  Helpline
                </li>
              </ul>
            </section>

            <section className='my-3'>
              <h2 className='display-6'>Contents Insurance</h2>
              <p>
                We can also provide cover for up to £50,000 for the Landlords
                contents left in the property.
              </p>
              <p>On top of that, our Landlords insurance also: </p>
              <ul className='list-group list-group-flush'>
                <li className='list-group-item'>
                  Covers fire, lightning, theft, malicious damage, explosion,
                  escape of water or oil and subsidence
                </li>
                <li className='list-group-item'>
                  Provides legal liability cover to the public caused by your
                  contents.
                </li>
                <li className='list-group-item'>
                  Gives you the option to include extended accidental damage
                  cover for incidents such as spillages on carpets or damage to
                  your furniture.
                </li>
                <li className='list-group-item'>
                  Covers theft of the Landlords contents from a garage or
                  outbuilding
                </li>
              </ul>
            </section>

            <section className='my-3'>
              <h2 className='display-6'>
                Landlords Professional Fees, Tax & Rent Arrears
              </h2>

              <p>Under the Landlords policy you can also include: </p>
              <ul className='list-group list-group-flush'>
                <li className='list-group-item'>
                  Legal costs of up to £25,000
                </li>
                <li className='list-group-item'>
                  Payment of rent arrears of up to £15,000 (dependent on monthly
                  rental income)
                </li>
              </ul>
            </section>
            <Cta />
          </Article>
        </main>
      </Layout>
    </>
  )
}

export default LandlordsInsurance
